import config from "../../../config";
import { useEffect, useState } from "react";
import Table from "../../../components/tables/Table";
import UmaSelect from "../../../components/elements/select/UmaSelect";
import CardCollection from "../../../components/elements/card/CardCollection";
import Card from "../../../components/elements/card/Card";
import "./Attendance.css";

const NewAttend = ({ lessonTypes, purchaseTypes, lessonPricing }) => {
    // Array Data
    const [lessons, setLessons] = useState(null);
    const [members, setMembers] = useState(null);
    const [attendance, setAttendance] = useState(null);
    const [memberTokens, setMemberTokens] = useState(null);

    // Active Data
    const [activeLessonId, setActiveLessonId] = useState(null);
    const [activeMemberId, setActiveMemberId] = useState(null);
    const [availableTokens, setAvailableTokens] = useState(null);

    const [purchaseType, setPurchaseType] = useState(null);
    const [lessonPrice, setLessonPrice] = useState(0);

    // Form State Data
    const [noPayment, setNoPayment] = useState(false);
    const [confirmPayment, setConfirmPayment] = useState(false);

    // Get data from server
    const getLessonsFromServer = () => {
        let table = new Map();
        fetch(`${config.SERVER_IP}/lessons`)
            .then((data) => data.json())
            .then((data) => {
                data.lessons.map(
                    (lesson) => (table = new Map(table).set(lesson.id, lesson))
                );
                setLessons(table);
                setActiveLessonId(table.keys().next().value);
                for (const [key, value] of lessonPricing) {
                    if (
                        value.lesson_type_id ===
                            table.entries().next().value[1].type_id &&
                        value.lesson_purchase_type_id === 1
                    ) {
                        setPurchaseType(parseInt(key));
                        // console.log(value.price)
                        setLessonPrice(value.price);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getMembersFromServer = () => {
        let table = new Map();
        fetch(`${config.SERVER_IP}/members`)
            .then((data) => data.json())
            .then((data) => {
                data.members.map(
                    (member) => (table = new Map(table).set(member.id, member))
                );
                setMembers(table);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getAttendance = (lessonId) => {
        if (lessonId === null) return;

        fetch(`${config.SERVER_IP}/attendance/lesson/${lessonId}`)
            .then((data) => data.json())
            .then((data) => {
                if (data.message) {
                    console.log("No attendance found for lesson");
                    setAttendance(new Map());
                    return;
                }

                let map = new Map();
                data.attendees.map((att) => {
                    map = new Map(map.set(att.member_id, att));
                });
                setAttendance(map);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getMemberTokens = (memberId, lessonTypeId) => {
        fetch(`${config.SERVER_IP}/tokens/member/${memberId}`)
            .then((data) => data.json())
            .then((data) => {
                if (data.message) {
                    console.log("No tokens found for member");
                    setAvailableTokens(0);
                    return;
                }

                let map = new Map();
                let tokenCount = 0;
                data.active_tokens.map((token) => {
                    map = new Map(map.set(token.id, token));
                    if (
                        token.lesson_type_id === null ||
                        token.lesson_type_id === lessonTypeId
                    ) {
                        tokenCount++;
                    }
                });
                setMemberTokens(map);
                setAvailableTokens(tokenCount);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const attendLesson = async (lessonId, memberId, tokenId) => {
        let payload = {
            member_id: memberId,
        };

        if (tokenId) payload.token_id = tokenId;

        let requestURI = `${config.SERVER_IP}/attendance/lesson/${lessonId}`;
        let requestMethod = "POST";

        const request = new Request(requestURI, {
            method: requestMethod,
            body: JSON.stringify(payload),
            headers: new Headers({
                "Content-Type": "application/json",
            }),
        });

        let responseStatus = null;
        try {
            const res = await fetch(request);
            responseStatus = res.status;
        } catch (error) {
            console.log(error);
        }

        if (responseStatus == 200 || responseStatus == 201) {
            if (tokenId && memberTokens !== null) memberTokens.delete(tokenId);
            getAttendance(lessonId);
            getMemberTokens(
                activeMemberId,
                lessons.get(activeLessonId).type_id
            );
        } else console.log(responseStatus);
    };

    const purchaseLesson = async (
        memberId,
        lessonTypeId,
        purchaseTypeId,
        paymentMethodId,
        total,
        callback
    ) => {
        let payload = {
            member_id: parseInt(memberId),
            lesson_type_id: lessonTypeId,
            purchase_type_id: 1,
            payment_method_id: paymentMethodId,
            total: total,
        };

        let requestURI = `${config.SERVER_IP}/lessonPurchases`;
        let requestMethod = "POST";

        const request = new Request(requestURI, {
            method: requestMethod,
            body: JSON.stringify(payload),
            headers: new Headers({
                "Content-Type": "application/json",
            }),
        });

        let responseStatus = null;
        let tokens = null;
        try {
            const res = await fetch(request);
            let content = await res.json();
            console.log(content);
            // tokens = content.tokens;
            responseStatus = res.status;
        } catch (error) {
            console.log(error);
        }

        if (responseStatus === 200 || responseStatus === 201) {
            getMemberTokens(activeMemberId, lessonTypeId);
            // attendLesson(activeLessonId, memberId, tokens[0].id);
        } else console.log(responseStatus);
    };

    // Validate data
    const getNextValidToken = () => {
        let validTokenId = null;

        if (memberTokens === null || memberTokens.size === 0) return null;

        for (const [key, data] of memberTokens) {
            if (
                data.lesson_type_id === null ||
                data.lesson_type_id ===
                    lessons.get(parseInt(activeLessonId)).type_id
            ) {
                validTokenId = key;
                continue;
            }
        }

        if (!validTokenId) return null;
        return validTokenId;
    };

    // Form actions
    const onAddClicked = (evt) => {
        evt.preventDefault();

        setNoPayment(false);
        setConfirmPayment(false);

        if (activeLessonId === null || activeLessonId === 0) return;
        if (activeMemberId === null || activeMemberId === 0) return;

        if (availableTokens === null || availableTokens === 0) {
            setNoPayment(true);
            return;
        }

        let tokenId = getNextValidToken();
        if (tokenId !== null) {
            attendLesson(activeLessonId, activeMemberId, tokenId);
            return;
        }
    };

    const onPayNowClicked = (evt) => {
        evt.preventDefault();
        setConfirmPayment(true);
        setNoPayment(false);
    };

    const onPayLaterClicked = (evt) => {
        evt.preventDefault();
        attendLesson(activeLessonId, activeMemberId);
        // TODO: Owe money
        setNoPayment(false);
    };

    const onFreeLessonClicked = (evt) => {
        evt.preventDefault();
        purchaseLesson(
            activeMemberId,
            parseInt(lessons.get(activeLessonId).type_id),
            purchaseType,
            1,
            0
        );
        setNoPayment(false);
    };

    const onNoPaymentFoundCancelClicked = (evt) => {
        evt.preventDefault();
        setNoPayment(false);
    };

    const onPaidCashClicked = (evt) => {
        evt.preventDefault();
        purchaseLesson(
            activeMemberId,
            parseInt(lessons.get(activeLessonId).type_id),
            purchaseType,
            1,
            lessonPrice
        );

        setConfirmPayment(false);
    };

    const onPaidCardClicked = (evt) => {
        evt.preventDefault();
        purchaseLesson(
            activeMemberId,
            parseInt(lessons.get(activeLessonId).type_id),
            purchaseType,
            2,
            lessonPrice
        );

        setConfirmPayment(false);
    };

    const onConfirmPurchaseCancelClicked = (evt) => {
        evt.preventDefault();
        setConfirmPayment(false);
    };

    // Load data
    useEffect(() => {
        getMembersFromServer();
    }, []);

    useEffect(() => {
        getLessonsFromServer();
    }, [lessonTypes]);

    useEffect(() => {
        getAttendance(activeLessonId);
    }, [members, lessons, activeLessonId]);

    useEffect(() => {
        if (activeLessonId && activeMemberId) {
            getMemberTokens(
                activeMemberId,
                lessons.get(activeLessonId).type_id
            );
        }
    }, [activeLessonId, activeMemberId]);

    // Render
    return (
        <main>
            <h1>Attendance</h1>

            {lessons && attendance && activeLessonId && (
                <CardCollection>
                    <Card
                        title={"Type"}
                        info={`${
                            lessonTypes.get(
                                parseInt(lessons.get(activeLessonId).type_id)
                            ).name
                        }`}
                    />
                    <Card
                        title={"Date"}
                        info={`${
                            lessons.get(activeLessonId).date.split("T")[0]
                        }`}
                    />
                    <Card
                        title={"Time"}
                        info={`${lessons.get(activeLessonId).start_time} - ${
                            lessons.get(activeLessonId).end_time
                        }`}
                    />
                    <Card title={"Attending"} info={`${attendance.size}`} />
                </CardCollection>
            )}

            <form
                id="attendanceForm"
                onSubmit={(e) => {
                    e.preventDefault();
                }}
            >
                <label>Lesson:</label>

                {lessons && lessonPricing && (
                    <UmaSelect
                        defaultId={lessons.entries().next().value[0]}
                        idKey={"id"}
                        valueKey={"display_text"}
                        values={lessons}
                        updateKey={(id) => {
                            setConfirmPayment(false);
                            setNoPayment(false);
                            setActiveLessonId(parseInt(id));
                            for (const [key, value] of lessonPricing) {
                                if (
                                    value.lesson_type_id ===
                                        lessons.get(parseInt(id)).type_id &&
                                    value.lesson_purchase_type_id === 1
                                ) {
                                    setPurchaseType(parseInt(key));
                                    console.log(value.price)
                                    setLessonPrice(value.price);
                                }
                            }
                        }}
                        required
                    />
                )}

                <label>Member:</label>
                {members && (
                    <UmaSelect
                        defaultId={0}
                        idKey={"id"}
                        valueKey={"display_name"}
                        values={members}
                        updateKey={(id) => {
                            setConfirmPayment(false);
                            setNoPayment(false);
                            if (id !== 0) setActiveMemberId(id);
                        }}
                    />
                )}

                <label>Available Tokens:</label>
                {availableTokens && <label>{availableTokens}</label>}

                <button
                    className="btn btn-primary"
                    type="submit"
                    onClick={onAddClicked}
                    disabled={
                        activeMemberId == 0 ||
                        activeMemberId == null ||
                        attendance.has(parseInt(activeMemberId))
                    }
                >
                    Add Member
                </button>
            </form>

            {noPayment && (
                <div id="attendance-payment-form">
                    <h2>No Payment Found</h2>
                    <button onClick={onPayNowClicked}>Pay Now</button>
                    <button onClick={onPayLaterClicked} disabled>Pay Later</button>
                    <button onClick={onFreeLessonClicked}>
                        Add Free Lesson
                    </button>
                    <button onClick={onNoPaymentFoundCancelClicked}>
                        Cancel
                    </button>
                </div>
            )}

            {confirmPayment && (
                <div id="attendance-confirm-payment">
                    <h2>Purchase Lesson</h2>
                    <p>
                        Lesson: {lessonTypes.get(lessons.get(activeLessonId).type_id).name}
                    </p>
                    <p>
                        Sessions:{" "}
                        {purchaseTypes.size > 0 &&
                            purchaseTypes.entries().next().value[1].name}
                    </p>
                    <p>Price: £{(lessonPrice / 100).toFixed(2)}</p>

                    <button onClick={onPaidCashClicked}>Paid Cash</button>
                    <button onClick={onPaidCardClicked}>Paid Card</button>
                    <button onClick={onConfirmPurchaseCancelClicked}>
                        Cancel
                    </button>
                </div>
            )}

            {attendance && members && (
                <Table
                    headings={["Member", "Time"]}
                    rows={Array.from(attendance, ([id, data]) => [
                        id,
                        members.get(data.member_id).display_name,
                        data.attendance_date.split("T")[1].split(".")[0],
                    ])}
                    recordCallback={(id) => {}}
                />
            )}

            <div></div>
        </main>
    );
};

export default NewAttend;
