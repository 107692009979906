import { NavLink, Outlet } from "react-router-dom";
import { useState } from "react";
import "./Navigation.css";

const Navigation = ({ setToken }) => {
    const [showNav, setShowNav] = useState(false);

    const navSelected = ({ isActive }) => {
        return {
            color: isActive ? "var(--white)" : "var(--primary-color)",
            background: isActive ? "#191919" : "#121212",
        };
    };

    const closeNav = () => {
        setShowNav(false);
    };

    return (
        <>
            <div
                id="nav-hamburger"
                className={`${showNav ? "naviconActive" : ""}`}
                onClick={() => {
                    setShowNav(!showNav);
                }}
            >
                <span className="navicon"></span>
            </div>
            <nav className={`${showNav ? "showNavMobile" : ""}`}>
                <ul className="menu">
                    <li>
                        <NavLink
                            end
                            style={navSelected}
                            to="/"
                            onClick={closeNav}
                        >
                            Dashboard
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            style={navSelected}
                            to="/members"
                            onClick={closeNav}
                        >
                            Members
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            style={navSelected}
                            to="/lessons"
                            onClick={closeNav}
                        >
                            Lessons
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            style={navSelected}
                            to="/attendance"
                            onClick={closeNav}
                        >
                            Attendance
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            style={navSelected}
                            to="/purchase"
                            onClick={closeNav}
                        >
                            Purchase
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            style={navSelected}
                            to="/payments"
                            onClick={closeNav}
                        >
                            Payments
                        </NavLink>
                    </li>
                </ul>

                <a className="logoutButton" href="/" onClick={setToken}>
                    Logout
                </a>
            </nav>
            <Outlet />
        </>
    );
};

export default Navigation;
