import config from "../../../config";
import { useEffect, useState } from "react";
import Table from "../../../components/tables/Table";
import UmaSelect from "../../../components/elements/select/UmaSelect";
import "./LessonPurchase.css";

const LessonPurcahse = ({ lessonTypes, lessonPricing, paymentMethods }) => {
    const [members, setMembers] = useState(null);

    const [selectedLessonType, setSelectedLessonType] = useState(null);
    const [selectedPurchaseType, setSelectedPurchaseType] = useState(null);
    const [selectedMember, setSelectedMember] = useState(null);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

    const [price, setPrice] = useState(null);

    const [confirmPay, setConfirmPay] = useState(false);

    const getMembersFromServer = () => {
        let table = new Map();
        fetch(`${config.SERVER_IP}/members`)
            .then((data) => data.json())
            .then((data) => {
                data.members.map(
                    (member) => (table = new Map(table).set(member.id, member))
                );
                setMembers(table);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const purchaseLesson = async (
        memberId,
        lessonTypeId,
        purchaseTypeId,
        paymentMethodId,
        total
    ) => {
        let payload = {
            member_id: memberId,
            lesson_type_id: lessonTypeId,
            purchase_type_id: purchaseTypeId,
            payment_method_id: paymentMethodId,
            total: total,
        };

        console.log(payload);

        let requestURI = `${config.SERVER_IP}/lessonPurchases`;
        let requestMethod = "POST";

        const request = new Request(requestURI, {
            method: requestMethod,
            body: JSON.stringify(payload),
            headers: new Headers({
                "Content-Type": "application/json",
            }),
        });

        let responseStatus = null;
        try {
            const res = await fetch(request);
            responseStatus = res.status;
        } catch (error) {
            console.log(error);
        }

        if (responseStatus === 200 || responseStatus === 201) {
            console.log('Success');
        } else console.log(responseStatus);
    };

    useEffect(() => {
        getMembersFromServer();
    }, []);

    useEffect(() => {
        if (members === null) return;
        setSelectedLessonType(
            lessonPricing.entries().next().value[1].lesson_type_id
        );
        setSelectedPurchaseType(
            lessonPricing.entries().next().value[1].lesson_purchase_type_id
        );
        setSelectedMember(members.entries().next().value[0]);
        setSelectedPaymentMethod(paymentMethods.entries().next().value[0]);
        setPrice(lessonPricing.entries().next().value[1].price);
    }, [members]);

    const onAddClicked = (e) => {
        e.preventDefault();
        setConfirmPay(true);
    };

    const confirmPayment = async (e) => {
        e.preventDefault();
        await purchaseLesson(
            selectedMember,
            selectedLessonType,
            selectedPurchaseType,
            selectedPaymentMethod,
            price
        );
        setConfirmPay(false);
    };

    return (
        <main>
            <h1>Lesson Purchase</h1>
            <form id="lesson-purchase-form">
                <label>Lesson</label>
                {lessonPricing.size > 0 && (
                    <UmaSelect
                        defaultId={lessonPricing.entries().next().value[0]}
                        idKey={"id"}
                        valueKey={"display_text"}
                        values={lessonPricing}
                        updateKey={(id) => {
                            if (lessonPricing === null) return;
                            setSelectedLessonType(
                                lessonPricing.get(parseInt(id)).lesson_type_id
                            );
                            setSelectedPurchaseType(
                                lessonPricing.get(parseInt(id))
                                    .lesson_purchase_type_id
                            );
                            setPrice(lessonPricing.get(parseInt(id)).price);
                        }}
                        required
                        disabled={confirmPay}
                    />
                )}

                <label>Member</label>
                {members && (
                    <UmaSelect
                        defaultId={members.entries().next().value[0]}
                        idKey={"id"}
                        valueKey={"display_name"}
                        values={members}
                        updateKey={(id) => {
                            setSelectedMember(id);
                        }}
                        required
                        disabled={confirmPay}
                    />
                )}

                <label>Payment Method</label>
                {paymentMethods && (
                    <UmaSelect
                        defaultId={paymentMethods.entries().next().value[0]}
                        idKey={"id"}
                        valueKey={"name"}
                        values={paymentMethods}
                        updateKey={(id) => {
                            setSelectedPaymentMethod(id);
                        }}
                        required
                        disabled={confirmPay}
                    />
                )}
                <label>Price</label>
                <label>£{(price / 100).toFixed(2)}</label>

                {!confirmPay && (
                    <button
                        id="lesson-purchase-button"
                        className="btn btn-primary"
                        type="submit"
                        onClick={onAddClicked}
                    >
                        Purchase
                    </button>
                )}

                {confirmPay && (
                    <button
                        id="lesson-purchase-confirm-button"
                        className="btn btn-primary"
                        type="submit"
                        onClick={confirmPayment}
                    >
                        Confirm Payment
                    </button>
                )}

                {confirmPay && (
                    <button
                        id="lesson-purchase-cancel-button"
                        className="btn"
                        type="cancel"
                        onClick={confirmPayment}
                    >
                        Cancel
                    </button>
                )}
            </form>

            {lessonPricing && (
                <Table
                    headings={["Lesson Type", "Purchase Type", "Price"]}
                    rows={Array.from(lessonPricing, ([id, data]) => [
                        id,
                        data.lesson_type_name,
                        data.purchase_type_name,
                        `£${(data.price / 100).toFixed(2)}`,
                    ])}
                    recordCallback={(id) => {}}
                />
            )}
        </main>
    );
};

export default LessonPurcahse;
