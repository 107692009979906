import { useEffect, useState } from "react";
import Card from "../../../components/elements/card/Card";
import CardCollection from "../../../components/elements/card/CardCollection";
import config from "../../../config";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    ResponsiveContainer,
} from "recharts";
import "./Home.css";

const Home = () => {
    const [todaysTotal, setTodaysTotal] = useState("0");
    const [weeksTotal, setWeeksTotal] = useState("0");
    const [todaysAttendees, setTodaysAttendees] = useState("0");
    const [weeksAttendees, setWeeksAttendees] = useState("N/A");

    const [incomeData, setIncomeData] = useState(null);
    const [attendanceData, setAttendanceData] = useState(null);

    const getDayName = (dayNo) => {
        switch (dayNo) {
            case 0:
                return "Sun";
            case 1:
                return "Mon";
            case 2:
                return "Tues";
            case 3:
                return "Wed";
            case 4:
                return "Thur";
            case 5:
                return "Fri";
            case 6:
                return "Sat";
            default:
                return "N/A";
        }
    };

    // Get statistics
    useEffect(() => {
        fetch(`${config.SERVER_IP}/payments/total/today`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        })
            .then((data) => data.json())
            .then((data) => {
                let total = data.total;
                setTodaysTotal(total / 100);
            })
            .catch((err) => {
                console.log(err);
            });

        fetch(`${config.SERVER_IP}/payments/total/week`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        })
            .then((data) => data.json())
            .then((data) => {
                let total = data.total;
                setWeeksTotal(total / 100);
            })
            .catch((err) => {
                console.log(err);
            });

        fetch(`${config.SERVER_IP}/attendance/total/today`, {
            method: "GET",
            headers: new Headers({
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            }),
        })
            .then((data) => data.json())
            .then((data) => {
                let total = data.total;
                setTodaysAttendees(total);
            })
            .catch((err) => {
                console.log(err);
            });

        fetch(`${config.SERVER_IP}/attendance/total/week`, {
            method: "GET",
            headers: new Headers({
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            }),
        })
            .then((data) => data.json())
            .then((data) => {
                let total = data.total;
                setWeeksAttendees(total);
            })
            .catch((err) => {
                console.log(err);
            });

        fetch(`${config.SERVER_IP}/payments/graph`, {
            method: "GET",
            headers: new Headers({
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            }),
        })
            .then((data) => data.json())
            .then((data) => {
                let results = data.payments[0];
                let totals = [];
                let date = new Date();
                for (let i = 0; i < 7; i++) {
                    let day = new Date().setDate(date.getDate() - i);
                    let dayName = getDayName(new Date(day).getDay());
                    // console.log(results.d0)
                    let d = {
                        name: dayName,
                        tw: parseInt(results[`d${i}`]) / 100,
                        lw: parseInt(results[`d${i + 7}`]) / 100,
                    };
                    totals.push(d);
                }
                // console.log(totals);
                setIncomeData(totals);
            })
            .catch((err) => {
                console.log(err);
            });

        fetch(`${config.SERVER_IP}/attendance/graph`, {
            method: "GET",
            headers: new Headers({
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            }),
        })
            .then((data) => data.json())
            .then((data) => {
                let results = data.attendances[0];
                let totals = [];
                let date = new Date();
                for (let i = 0; i < 7; i++) {
                    let day = new Date().setDate(date.getDate() - i);
                    let dayName = getDayName(new Date(day).getDay());
                    let d = {
                        name: dayName,
                        tw: parseInt(results[`d${i}`]),
                        lw: parseInt(results[`d${i + 7}`]),
                    };
                    totals.push(d);
                }
                // console.log(totals);
                setAttendanceData(totals);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    return (
        <main className="homePage">
            <h1>{new Date().toDateString()}</h1>
            <CardCollection>
                <Card title={"Income Today"} info={`£${todaysTotal}`} />
                <Card title={"Income Week"} info={`£${weeksTotal}`} />
                <Card title={"Attendees Today"} info={todaysAttendees} />
                <Card title={"Attendees Week"} info={weeksAttendees} />
            </CardCollection>

            <h2>Income - Past 7 Days</h2>
            {incomeData && (
                <ResponsiveContainer
                    width="100%"
                    minHeight="200px"
                    maxHeight="200px"
                >
                    <LineChart
                        width={500}
                        height={400}
                        data={incomeData}
                        margin={{
                            top: 20,
                            right: 20,
                            left: 20,
                            bottom: 0,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Line
                            type="monotone"
                            dataKey="tw"
                            stroke="#FF7E07"
                            activeDot={{ r: 8 }}
                        />
                        <Line type="monotone" dataKey="lw" stroke="#FFCE54" />
                    </LineChart>
                </ResponsiveContainer>
            )}

            <h2>Attendees - Past 7 Days</h2>
            {attendanceData && (
                <ResponsiveContainer
                    width="100%"
                    minHeight="200px"
                    maxHeight="200px"
                >
                    <LineChart
                        width={500}
                        height={300}
                        data={attendanceData}
                        margin={{
                            top: 20,
                            right: 20,
                            left: 20,
                            bottom: 0,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Line
                            type="monotone"
                            dataKey="tw"
                            stroke="#FF7E07"
                            activeDot={{ r: 8 }}
                        />
                        <Line type="monotone" dataKey="lw" stroke="#FFCE54" />
                    </LineChart>
                </ResponsiveContainer>
            )}
        </main>
    );
};

export default Home;
