import { useState } from "react";
import "./Login.css";
import config from "../config";

const apiLocation = `${config.SERVER_IP}/login`;

const Login = ({ setToken }) => {

    const [display, setDisplay] = useState("none");

    const handleSubmit = async (event) => {
        event.preventDefault();

        setDisplay("none");

        const request = new Request(apiLocation, {
            method: "POST",
            body: JSON.stringify({
                username: event.target.usernameInput.value,
                password: event.target.passwordInput.value,
            }),
            headers: new Headers({
                "Content-Type": "application/json",
            }),
        });
    
        try {
            await fetch(request)
                .then((data) => data.json())
                .then((data) => {
                    console.log(data.token);
                    localStorage.setItem("token", data.token);
                    if (data.token) setToken({ token: data.token });
                    else setDisplay("block");
                })
                .catch((err) => {
                    setDisplay("block");
                });
        } catch (error) {
            setDisplay("block");
        }
    };


    return (
        <div className="loginContainer">
            <div>
                <img className="loginLogo" src={'./uma-logo.png'} alt="UMA Logo" />
                <h1>Upgrade CRM Login</h1>
                <form className="loginForm" onSubmit={handleSubmit}>
                    <label className="login-input-label">Username: </label>
                    <input
                        id="usernameInput"
                        type="text"
                        className="input-text"
                        required
                    />
                    <label className="login-input-label">Password: </label>
                    <input
                        id="passwordInput"
                        type="password"
                        className="input-text"
                        required
                    />
                    <span style={{ display }} >Username or password incorrect.<br/> Please try again.</span>
                    <button
                        type="submit"
                        id="loginButton"
                        className="btn btn-login"
                    >
                        Login
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Login;
